// 申遗承诺
import SHZT from './sn-table-column-shzt';
import DJZT from './sn-table-column-djzt';

const selfData = {
    loading: false,
    snTable: {
        table: {
            data: [],
            rowKey: 'ID',
        },
        columns: {
            // 数据列
            columnsData: [
                {
                    label: "病害名称",
                    prop: "BHMC",
                    minWidth: ""
                },
                {
                    label: "遗产要素",
                    prop: "YCYSMC",
                    minWidth: ""
                }, {
                    label: "病害编号",
                    prop: "BHBH",
                    minWidth: ""
                }, {
                    label: "病害类型",
                    prop: "BHLX_BM",
                    minWidth: ""
                }, {
                    label: "病害位置",
                    prop: "BHWZ",
                    minWidth: ""
                }, {
                    label: '病害示意图',
                    prop: 'BHSYT',
                    minWidth: 105,
                    showImg: true,
                },
                SHZT,
                DJZT
            ],
            // 操作列
            columnsBtn: {
                buttonData: []
            }
        },
    },
    snSearch: {
        inputData: [{
            label: '关键字',
            placeholder: "请输入关键字",
            value: 'keyword',
            operateType: 'search',
            isShow: true,
            default: ""
        }],
    },
    snButton: {
        buttonData: [{
            btnType: 'button',
            operateType: 'toDiseaseMap',
            name: '地图视图',
            round: true,
            backColor: '#28ccd9',
            color: '#fff'
        }, {
            btnType: 'button',
            operateType: 'buttonExport',
            showMethod: 'showExportBtn',
            name: '导出',
            round: true,
            backColor: '#ffcc66',
            color: '#fff'
        }]
    }
};
export default selfData;